import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import { useGetUserFromToken } from 'new/modules/MembershipPlan/hooks';
import { useMemberTabsStore } from 'new/modules/MembersPage/hooks';
import { Id } from 'new/api/types';

const cookies = new Cookies();

type ViewAsLocationStore = {
  locationId: string | undefined;
  setLocationId: (officeId: string | undefined) => void;
};

const useViewAsLocation = create<
  ViewAsLocationStore,
  SetState<ViewAsLocationStore>,
  GetState<ViewAsLocationStore>,
  StoreApiWithPersist<ViewAsLocationStore>
>(
  persist(
    (set) => ({
      locationId: undefined as ViewAsLocationStore['locationId'],
      setLocationId: (locationId) =>
        set(() => ({
          locationId,
        })),
    }),
    { name: 'viewAsLocation', version: 1, getStorage: () => sessionStorage },
  ),
);

const useActiveOffice = () => {
  const { locationId, setLocationId } = useViewAsLocation();
  const activeOfficeId: string = locationId || cookies.get('officeId') || '';
  const { resetMemberTabs } = useMemberTabsStore();
  const {
    data: user,
    isFetching,
    refetch: refetchUser,
  } = useGetUserFromToken();
  const { type } = user?.data || {};
  const isOfficeAdmin = type === 'office_admin';
  const isSuperAdmin = type === 'dentist';
  const isAdmin = isOfficeAdmin || isSuperAdmin;
  const isMultiLocation =
    user?.data?.otherOffices && user.data.otherOffices.length > 1;
  const orgId = user?.data.linkedWith ?? '';
  const isBillingDisabled = user?.billing_disabled == 'true';
  const globalBanner = user?.data.globalBanner;
  const legacyPricing = user?.data.legacyPricing;

  const refetch = () => {
    console.log('REFETCH');
    refetchUser();
  };
  const setActiveLocationId = (activeOfficeId: Id) => {
    if (
      cookies.get('officeId') != '' ||
      user?.data?.tieredPayment?.tierLevel != 0
    )
      cookies.set('officeId', activeOfficeId, { path: '/' });
    resetMemberTabs();
    refetch();
  };

  const removeActiveLocationId = () => {
    cookies.set('officeId', '', { path: '/' });
  };

  useEffect(() => {
    if (!!user && !activeOfficeId.length) {
      const { id } = user?.data.dentistInfo || {};

      if (id) {
        setActiveLocationId(id);
      }
    }
  }, [user, activeOfficeId]);

  return {
    user,
    isLoadingUser: isFetching,
    isAdmin,
    isSuperAdmin,
    isOfficeAdmin,
    isBillingDisabled,
    isMultiLocation,
    activeOfficeId,
    orgId,
    globalBanner,
    legacyPricing,
    setActiveLocationId,
    removeActiveLocationId,
    setViewAsLocationId: setLocationId,
    refetch,
  };
};

export default useActiveOffice;
