/*
Admin Members Page Selectors
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
// lib
import moment from 'moment';
import { createSelector } from 'reselect';
import { US_STATES } from 'common/constants';

// app
import { selectCurrentUser } from 'containers/App/selectors';

/*
Selectors
================================================================================
*/
const domainSelector = state => state.adminDentistsPage;

/*
Fetch
------------------------------------------------------------
*/
const selectDentists = createSelector(
  domainSelector,
  (substate) => {
    if ( substate.allDentists !== null
    ) {
      return substate.allDentists
    }else{
      return substate.allDentists
    }
  }
);

const selectMembers = createSelector(
    domainSelector,
    (substate) => substate.allMembers
);

const selectManagers = createSelector(
  domainSelector,
  (substate) => substate.managers
);

const selectSupports = createSelector(
  domainSelector,
  (substate) => substate.supports
);

const selectDentistDetails = createSelector(
  domainSelector,
  (substate) =>{
     return substate.dentistDetails
  }
);

const selectDentistMembers = createSelector(
  domainSelector,
  (substate) => substate.dentistMembers
);

const selectDentistReviews = createSelector(
  domainSelector,
  (substate) => substate.dentistReviews
);

const selectStats = createSelector(
  domainSelector,
  (substate) => {
    if(substate==undefined) return null;
    else return substate.stats
  }
);

const selectDentistReports = createSelector(
  domainSelector,
  (substate) => substate.dentistReports
);

const selectManagerReports = createSelector(
  domainSelector,
  (substate) => substate.managerReports
);

/* Getters
 * ------------------------------------------------------ */
const selectSelectedDentist = createSelector(
  domainSelector,
  (substate) => substate.selectedDentist
);

const selectSelectedMember = createSelector(
    domainSelector,
    (substate) => substate.selectedMember
);

const selectSelectedManager = createSelector(
  domainSelector,
  (substate) => substate.selectedManager
);

const selectEditingDentistInfoId = createSelector(
  domainSelector,
  (substate) => substate.editingDentistInfoId
);

/*
Search / Sort
------------------------------------------------------------
*/
const selectSearch = createSelector(
  domainSelector,
  (substate) => substate.searchName
);

const selectPage = createSelector(
    domainSelector,
    (substate) => substate.pageName
);

const selectSort = createSelector(
  domainSelector,
  (substate) => substate.sortStatus
);

const selectFilter = createSelector(
  domainSelector,
  (substate) => substate.filterStatus
);

const selectRefundingMember = createSelector(
  domainSelector,
  (substate) => substate.refundingMember
);

const selectChargingMember = createSelector(
  domainSelector,
  (substate) => substate.chargingMember
);

const selectDisputeId = createSelector(
  domainSelector,
  (substate) => substate.disputeId
);

const selectDispute = createSelector(
  domainSelector,
  (substate) => substate.disputeData
);

const selectPlanChangeMember = createSelector(
  domainSelector,
  (substate) => substate.planChangeMember
);

const selectMemberships = createSelector(
  domainSelector,
  (substate) => substate.memberships
);


const selectDrawDateMember = createSelector(
  domainSelector,
  (substate) => substate.drawDateMember
);

const selectEnrollNoCharge = createSelector(
  domainSelector,
  (substate) => substate.enrollNoCharge
);

const selectToCashMember = createSelector(
  domainSelector,
  (substate) => substate.toCashMember
);

const selectBackdateMember = createSelector(
  domainSelector,
  (substate) => substate.backdateMember
);

const selectUpgradeProgress = createSelector(
  domainSelector,
  (substate) => substate.upgradeProgress
);

const selectMasterReportsDates = createSelector(
  domainSelector,
  (substate) => substate.masterReportsDates
);

const selectProcessedDentists = createSelector(
  selectDentists,
  selectSearch,
  selectSort,
  selectFilter,
  selectPage,
  (dentists, searchName, sortMethod,filterMethod,pageName) => {
    // precondition: haven't fetched the dentists yet
    if (dentists === null) {
      return dentists;
    }
    //let pageName='members';
    let processedDentists = dentists;


    return processedDentists;
  }
);

const selectProcessedMembers = createSelector(
    selectMembers,
    selectSearch,
    selectSort,
    selectFilter,
    selectPage,
    (members, searchName, sortMethod,filterMethod,pageName) => {
    // precondition: haven't fetched the dentists yet
    if (members === null) {
    return members;
}

//let pageName='members';
let processedMembers = members;

if(searchName==null||searchName==""){
  processedMembers=[];
}
// search
// if search, then search everyone's dentists
// if (searchName !== null) {
//     searchName = searchName.toLowerCase();

//     processedMembers = members.filter((member) => {
//         let matchesDentist=false;
//         let memberName=member.firstName.toString() + ' ' + member.lastName.toString();
//         if(matchesDentist===false) matchesDentist = memberName.toLowerCase().indexOf(searchName) > -1;
//         let email=member.email.toString();
//         if(matchesDentist===false) matchesDentist=email.toLowerCase().indexOf(searchName) > -1;
//         if(matchesDentist==false) matchesDentist=member.stripeCustomerId==searchName;
//         if(matchesDentist==false) matchesDentist=member.stripeCustomerIdConnected==searchName;
//         if(matchesDentist===false) matchesDentist=member.id==searchName
//         if(member.members.length>0){
//           member.members.map(member2=>{
//             let memberName=member2.firstName.toString() + ' ' + member2.lastName.toString();
//             if(matchesDentist===false) matchesDentist = memberName.toLowerCase().indexOf(searchName) > -1;
//             if(matchesDentist===false) matchesDentist=member2.id==searchName
//           })
//         }
//     return matchesDentist;
//   });
// }else{

    //sort
    // if (filterMethod === 'unassigned') {
    //     processedMembers = processedMembers
    //         .filter(pd => !pd.managerId);
    // } else {
    //     processedMembers = processedMembers
    //         .filter(pd => pd.managerId === Number(filterMethod));
    // }


return processedMembers;
}
);


const transferringMemberSelector = createSelector(
  domainSelector,
  substate => substate.transferringMember
);

const isTransferringMemberSelector = createSelector(
  domainSelector,
  substate => substate.isTransferringMember
);

const termsUpdateModalOpenSelector = createSelector(
  domainSelector,
  substate => substate.termsUpdateModalOpen
);

const isUpdatingTermsSelector = createSelector(
  domainSelector,
  substate => substate.isUpdatingTerms
);

const selectPausedDentists = createSelector(
  domainSelector,
  (substate) => {
    if ( substate.pausedDentists !== null
    ) {
      return substate.pausedDentists
    }else{
      return substate.pausedDentists
    }
  }
);

const selectPromos = createSelector(
  domainSelector,
  (substate) => {
    if ( substate.promos !== null
    ) {
      return substate.promos
    }else{
      return substate.promos
    }
  }
);

const selectLoadingDentists = createSelector(
  domainSelector,
  (substate) => {
    return substate.loadingDentists; }
);

const selectDentistTotal = createSelector(
  domainSelector,
  (substate) => {
    return substate.dentistTotal; }
);

const selectNoMoreResults= createSelector(
  domainSelector,
  (substate) => {
    return substate.noMoreResults; }
);

const selectDentistList= createSelector(
  domainSelector,
  (substate) => {
    return substate.dentistList; }
);

/*
Export
------------------------------------------------------------
*/
export default domainSelector;

export {
  // fetch
  selectDentists,
  selectMembers,
  selectDentistDetails,
  selectDentistMembers,
  selectDentistReviews,
  selectStats,
  selectDentistReports,
  selectManagerReports,

  // getters
  selectSelectedMember,
  selectSelectedManager,
  selectSelectedDentist,
  selectEditingDentistInfoId,
  // search / sort dentists
  selectSearch,
  selectSort,
  selectFilter,
  selectPage,
  selectProcessedDentists,
  selectProcessedMembers,
  selectManagers,
  selectSupports,
  selectRefundingMember,
  selectChargingMember,
  selectDisputeId,
  selectDispute,
  selectDrawDateMember,
  selectEnrollNoCharge,
  selectToCashMember,
  selectBackdateMember,
  // reports
  selectMasterReportsDates,

  transferringMemberSelector,
  isTransferringMemberSelector,
  termsUpdateModalOpenSelector,
  isUpdatingTermsSelector,
  selectPlanChangeMember,
  selectMemberships,
  selectPausedDentists,
  selectPromos,
  selectLoadingDentists,
  selectDentistTotal,
  selectNoMoreResults,
  selectDentistList,
  selectUpgradeProgress
};
