const SeedPlansCustom = [
  {
    name: 'Exam & Eyewear',
    description:
      'One (1) Comprehensive Eye Exam to check your prescription and eye health each year (includes Advanced Diagnostic Testing of the retina and macula)\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_exam',
    recommendedPricingFormula: [
      ['1110', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D1110 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Eyewear Only + Contacts',
    description: "Eyewear Only Plan For those with eye health benefits that can be billed to a vision or medical plan (includes refraction: measurement of prescription)\n10% OFF Annual Supply of Contact Lenses\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance\n*Must have had eye health exam within the last 6 months if not performed by our office",
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: true,
    whitening: false,
    teledentistry: false,
    maxAge: 13,
    minAge: null,
    has_limit: true,
    ageLimit: null,
    limit_type: 'under',
    age_limit: 'under',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_refraction',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0120', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0120 x 2) + D0272
  },
  {
    name: 'Exam & Eyewear + Contacts',
    description:
    "One (1) Annual Comprehensive Eye Exam to check your prescription and eye health each year (includes Advanced Diagnostic Testing of the retina and macula)\n\nOne (1) Annual Contact Lens Examination (Soft Sphere/Toric/Multi-Focals and Spherical RGPs)\n10% OFF Annual Supply of Contact Lenses\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance",
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_exam_eyewear',
    recommendedPricingFormula: [
      ['4910', 4],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 4) + (D0120 x 2) + D0274 + (D0220 x 2)
  },{
    name: 'Eyewear Only',
    description: "Eyewear Only Plan For those with eye health benefits that can be billed to a vision or medical plan (includes refraction: measurement of prescription)\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance\n*Must have had eye health exam within the last 6 months if not performed by our office",
    codes: [

    ],
    price: null, 
    min_price:1,
    yearly_price:null,
    yearly_membership: [],
    subscription_age_group:'adult',
    type:'custom',
    emergency:true,
    fluoride:false,
    whitening:false,
    teledentistry:false,
    maxAge:null,
    minAge:null,
    age_limit: 'no_limit',
    limit_type: 'no_limit',
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual:true,
    yearly_active:true,
    monthly_active:true,
    presetType:'refraction_eyewear'
  },
];

export default SeedPlansCustom;
