import { Tenant } from 'new/types/tenants';
import pngLogo from 'new/assets/logos/VHQ-ondark.png';
import pngLogoSmall from 'new/assets/logos/VHQ-ondark.png';
import pngLogoOnLight from 'new/assets/logos/VHQ-onlight.png';
import { ReactComponent as SvgLogo } from 'new/assets/logos/VHQ-ondark.svg';
import { ReactComponent as SvgLogoSmall } from 'new/assets/logos/VHQ-ondark-small.svg';
import { ReactComponent as SvgLogoOnLight } from 'new/assets/logos/VHQ-onlight.svg';
import loginBg from 'new/assets/login_bg_vhq.png';
import { launchChecklistItems, pmsPlanOptions } from '../constants/const.vhq';

const vhq: Tenant = {
  id: 'vhq',
  name: 'VisionHQ',
  shortName: 'VHQ',
  supportEmail: 'support@vision-hq.com',
  successEmail: 'success@vision-hq.com',
  features: {
    setupBaa: false,
    gpp: false,
    pms: false,
    marketingOnlyLinks: true,
    businessConnect: false,
    defaultSpecialty: '1',
    upgradeOffice: false,
    seasonOfGivingWidget: true,
    feePerMember: false,
    leaderboardWidgetType: 'lightGreen',
  },
  constants: {
    launchRewards: {
      prize30: 25,
      prize60: 50,
      prize90: 100,
    },
    seasonOfGivingPrize: '$100, $50, and $25',
    seasonOfGivingImage:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+MMR+hat+in+circle.png',
    marketingTemplates:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+-+Email+%26+Text+Blasts+to+Patients+(Template).docx',
    marketingQrPdf1:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQPDFQR1.png',
    marketingQrPdf2:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQPDFQR2.png',
    marketingQrPdf3:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQPDFQR3.png',
    marketingQrPdf4:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQPDFQR4.png',
    marketingQrPdf5:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQPDFQR5.png',
    ucrPrefix: '',
    themePreview1:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/demo_simple.jpg',
    themePreview2:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/blue_and_green_enroll_2.jpg',
    themePreview3:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/yellow_charcoal_2.jpg',
    login: {
      fontFamilyContainer: undefined,
      fontFamilyTitle: undefined,
    },
    externalPatientLandingBaseUrl: '/accounts/signup/my-optometrist',
    pmsPlanOptions,
    launchChecklistItems,
    defaultMonthlyPrice: 99,
    defaultYearlyPrice: 990,
    files: {
      scriptingPdf:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/Scripting+%2B+Daily+Prep.pdf',
      quickstartGuidePdf:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ_Quick+Reference+Guide.pdf',
      memberSignaturePdf:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+In+Office+Member+Agreement+(1)+(1).pdf',
      termsAndConditions:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/Optometric+Membership+Plan+-+Terms++Conditions-July+2024.pdf',
      termsAndConditionsSpanish:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/Optometric+Membership+Plan+-+Terms++Conditions-July+2024.pdf',
    },
  },
  logos: {
    pngLogo,
    pngLogoOnLight,
    pngLogoSmall,
    SvgLogo,
    SvgLogoSmall,
    SvgLogoOnLight,
  },
  assets: {
    loginBg,
    printMaterialsFront:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Brochure+Mockup.png',
  },
  theme: {
    login: {
      buttonBg: '#25AB90',
      color: '#616161',
    },
    modal: {
      fontFamily: 'Roboto',
    },
    rocketRight: 0,
    rocketTop: 320,
  },
  socialSlides: [
    {
      image:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Social+1.png',
    },
    {
      image:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Social+2.png',
    },
    {
      image:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Social+3.png',
    },
    {
      image:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Social+4.png',
    },
    {
      image:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Social+5.png',
    },
    {
      image:
        'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+Social+6.png',
    },
  ],
  awsStorageUrl: 'https://visionsource-uploads.s3.amazonaws.com',
};

export default vhq;
