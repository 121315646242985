/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a neccessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';

import NavBar from 'components/NavBar';
import NavBarDashboard from 'components/NavBarDashboard';
import SideNavDashboard from 'components/SideNavDashboard';
import GlobalBanner from 'components/GlobalBanner';
import NavBarBusiness from 'components/NavBarBusiness';
import SideNavBusiness from 'components/SideNavBusiness';
import Footer from 'components/Footer';
import PageHeader from 'components/PageHeader';
import LoadingBar from 'react-redux-loading-bar';
import NavBarAdmin from 'components/NavBarAdmin';
import SideNavAdmin from 'components/SideNavAdmin';
import SideNavAssociation from 'components/SideNavAssociation';
import NavBarAssociation from 'components/NavBarAssociation';
import TimeoutModal from 'components/TimeoutModal';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import moment from 'moment';

import { selectCurrentUser, selectPageTitle, selectGlobalBanner } from 'containers/App/selectors';
import browserDetector from 'utils/browserDetector';
import IdleTimer from 'react-idle-timer';

import * as actions from './actions';
import styles from './styles.module.css';
import 'react-select/dist/react-select.css';
import Elevio from 'elevio/lib/react';
import findRoute from '../../new/routing/routes';
import ResetZustandStores from '../../new/components/ResetZustandStores';
import ResetCookies from '../../new/components/ResetCookies';
import IdleModal from '../../new/components/IdleModal';
import { checkFeatureFlag } from '../../utils/featureFlags';
import Helmet from 'react-helmet';

import favIcon from 'assets/favicon_vision.ico';
import AppHelmet from '../../new/components/AppHelmet';

export const newAppRoutes = [
  findRoute('EnrollPage').path,
  findRoute('MembershipStandardOnlyPage').path,
  findRoute('EmbeddedMembershipPage').path,
  findRoute('CheckoutPage').path,
  findRoute('SingleSetupPage').path,
  findRoute('MultiSetupPage').path,
  findRoute('MultiSetupCSVReview').path,
  findRoute('BillingPage').path,
  findRoute('TestPlaygroundPage').path,
  findRoute('TestLayoutPage').path,
  findRoute('LoginPage').path,
  findRoute('ResetPage').path,
  findRoute('ForgotPage').path,
  findRoute('AnalyticDashboardPage').path,
  findRoute('UserSettings').path,
  findRoute('BusinessConnectPage').path,
  findRoute('CommunityConnectPage').path,
  findRoute('MembersPage').path,
  findRoute('MembershipPlansManage').path,
  findRoute('OfficeSettings').path,
  findRoute('MarketingSpace').path,
  findRoute('InternalPatientSignup').path,
  findRoute('ExternalPatientSignup').path,
  findRoute('Reports').path,
  findRoute('InhouseFinancing').path,
  findRoute('Disputes').path,
  findRoute('MultiLocationDashboardPage').path,
  findRoute('MultiLocationReports').path,
  findRoute('ManageLocations').path,
  findRoute('ManageLocationsEdit').path,
  findRoute('ManageUsers').path,
  findRoute('ManagePlans').path,
  findRoute('ManageGroups').path,
  findRoute('MultiLocationBilling').path,
  findRoute('MultiLocationReports').path,
  findRoute('OrderInfoCards').path,
  findRoute('PlanBuilderPage').path,
  findRoute('SeasonOfGivingPage').path,
  findRoute('ExternalPatientLandingPage').path,
  findRoute('UpgradePlan').path,
];

const staticPages = [
  '/',
  '/accounts/signup/my-optometrist/', // dentistId added in the render function
  '/error/404-not-found',
  '/faq',
  '/learn-more',
  '/privacy',
  '/search',
  '/out-of-network',
  '/ppo-calculator',
  '/plan-builder',
];

const unThemedPages = [
  '/accounts/reset-password',
  '/error/404-not-found',
  '/faq',
  '/privacy',
  '/business-connect/',
  '/baa-signed',
  '/out-of-network',
  '/ppo-calculator',
  ...newAppRoutes,
];

const pagesWithSocialLinks = [
  '/',
  '/learn-more',
  '/search',
  // /marketplace/profile/123 handled separately since we need to account for the id at the end
];

const pagesWithDisclaimer = [
  '/learn-more',
  '/search',
  // `/` (home page) handled separately since it includes the footer component directly
  // `/marketplace/profile/123` handled separately since we need to account for the id at the end
];

const footerBlackList = [
  'payment-update',
  'my-optometrist',
  'out-of-network',
  'ppo-calculator',
  'baa-signed',
  ...newAppRoutes,
];

const mapDispatchToProps = {
  loadUserFromToken: actions.meFromToken,
  fetchAssociations: actions.fetchAssociations,
};

function mapStateToProps(state) {
  return {
    user: selectCurrentUser(state),
    pageTitle: selectPageTitle(state),
    globalBannerInfo: selectGlobalBanner(state)
  };
}

const getContainerClassName = (pathname, user, globalbanner) => {
  const shouldSkipContainer = newAppRoutes.some(route => pathname.includes(route));

  if (shouldSkipContainer) {
    return undefined;
  }

  return pathname.indexOf('dentist-new') != -1 ||
    pathname.indexOf('associations') != -1 ||
    (user.type === 'sm_business' &&
      pathname.indexOf('my-optometrist') == -1 &&
      pathname.indexOf('signup') == -1) ||
    pathname.indexOf('admin') != -1
    ? 'container-wrapper dentist-new' +
        (globalbanner != null ? ' globalbanneron' : '')
    : 'container-wrapper';
};

@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.state = {
      showSession: false,
      count: 1,
      removeElev: false,
      isLoaded: false,
      isReady: false,
      isOpened: false,
    };
  }

  static propTypes = {
    children: PropTypes.node,
    loadUserFromToken: PropTypes.func,
    user: PropTypes.oneOfType([
      PropTypes.bool, // will be `false` until loaded
      PropTypes.object,
    ]),
    pageTitle: PropTypes.string,
    location: PropTypes.object,
  };

  UNSAFE_componentWillMount() {
    // fetch associations for Optometrist signup page
    if (
      (this.props.associations == undefined ||
        this.props.associations.length == 0) &&
      this.props.location.pathname.indexOf('accounts') != -1 &&
      this.props.location.pathname.indexOf('my-optometrist') == -1 &&
      this.props.location.pathname.indexOf('login') == -1 &&
      this.props.location.pathname.indexOf('logout') == -1 &&
      this.props.location.pathname.indexOf('password') == -1
    ) {
      this.props.fetchAssociations();
    }

    const isNewAppRoute = newAppRoutes.some(route => this.props.location.pathname.includes(route));
    // Always load user details from the localStorage Token
    if(!isNewAppRoute) this.props.loadUserFromToken();
    if (browserDetector.isOld()) {
      // eslint-disable-next-line
      alert(browserDetector.warning);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && !prevProps.user) this.forceUpdate();
  }

  handleOnAction(event) {
    this.idleTimer.reset();
    //console.log(this.idleTimer.getRemainingTime())
  }

  closeSessionTimeout = () => {
    this.setState({ showSession: false });
    this.idleTimer.reset();
  };

  handleOnIdle(event) {
    if (this.props.user != null) this.setState({ showSession: true });
    else console.log('not loggedin');
  }

  runLogout = () => {
    this.setState({ showSession: false });
    cookies.set('userId', this.props.user.id, { path: '/' });
    //if(this.user.type!='super_admin'&&this.user.type!='almost_super')
    window.location.href = '/accounts/login';
  };

  render() {
    const {
      user,
      pageTitle,
      globalBannerInfo,
      location: { pathname, search },
    } = this.props;

    // NOTE: Hardcoded here to avoid the common layout
    if (pathname === '/') {
      return this.props.children;
    }

    const showSocialLinks =
      pagesWithSocialLinks.includes(pathname) ||
      pathname.substring(0, 21) === '/marketplace/profile/';

    const showDisclaimer =
      pagesWithDisclaimer.includes(pathname) ||
      pathname.substring(0, 21) === '/marketplace/profile/';

    let content = null;
    const childComponents = React.Children.toArray(this.props.children);
    const title =
      pageTitle &&
      pathname.indexOf('dentist-new') == -1 &&
      pathname.indexOf('admin') == -1 &&
      pathname.indexOf('associations') == -1 &&
      pathname.indexOf('payment-update') == -1 ? (
        <PageHeader pathname={pathname} title={pageTitle} user={user} />
      ) : null;

    if (user && user.id && user.type && user.type === 'dentist') {
      staticPages[1] = `/accounts/signup/my-optometrist/${user.id}`;
    }

    //Turn on and off global banner here.
    //let globalbanner = null;
    let globalbanner=(pathname.indexOf('dentist-new')!=-1&& globalBannerInfo!=null&&moment().format('X')<globalBannerInfo.end)?(<GlobalBanner globalBannerInfo={globalBannerInfo}/>):null;

    // the visitor is a logged in user AND is not on a static page
    let onUserPage = user && user.type && staticPages.indexOf(pathname) < 0;
    onUserPage = pathname.indexOf('businessconnect') != -1 ? false : onUserPage;
    onUserPage = pathname.indexOf('my-optometrist') != -1 ? true : onUserPage;
    onUserPage =
      pathname.indexOf('complete-business') != -1 ? false : onUserPage;
    if (onUserPage) {
      content = (
        <div styleName={getContainerClassName(pathname, user, globalbanner)}>
          {title}
          <div className="container">
            {/* <div className="col-md-12"> */}
            {/* <div styleName="content-wrapper"> */}
            {childComponents}
            {/* </div> */}
            {/* </div> */}
          </div>
          {!footerBlackList.find((route) => pathname.includes(route)) && (
            <Footer
              user={this.props.user}
              showSocialLinks={showSocialLinks}
              showDisclaimer={showDisclaimer}
              pathname={pathname}
            />
          )}
        </div>
      );
    } else {
      content = childComponents;
    }

    let wrapperStyle = 'wrapper--no-navbar';
    let navbar = null;
    let sidenavbar = null;
    let canTimeout = false;

    if (!unThemedPages.some(page => pathname.includes(page))) {
      wrapperStyle =
        pathname.indexOf('dentist-new') != -1
          ? 'wrapper-dentist-new'
          : 'wrapper';
      navbar =
        pathname.indexOf('small-business') != -1 ||
        pathname.indexOf('complete-business') != -1 ||
        pathname.indexOf('payment-update') != -1 ? null : pathname.indexOf(
            'dentist-new',
          ) != -1 || pathname.indexOf(findRoute('BillingPage').path) != -1 ? (
          <NavBarDashboard
            pathname={pathname}
            withbanner={globalbanner != null}
          />
        ) : (
          <NavBar pathname={pathname} />
        );
      sidenavbar =
        pathname.indexOf('dentist-new') != -1 ||
        pathname.indexOf(findRoute('BillingPage').path) != -1 ? (
          <SideNavDashboard
            pathname={pathname}
            withbanner={globalbanner != null}
          />
        ) : (
          ''
        );
      if (
        (user.type === 'admin' ||
          user.type == 'super_admin' ||
          user.type == 'almost_super') &&
        pathname.indexOf('admin') != -1
      ) {
        navbar = <NavBarAdmin pathname={pathname} />;
        sidenavbar = <SideNavAdmin pathname={pathname} />;
      } else if (
        (user.type == 'association' ||
          user.type == 'association_standard' ||
          user.type == 'affiliate') &&
        pathname.indexOf('associations') != -1
      ) {
        navbar = <NavBarAssociation pathname={pathname} />;
        sidenavbar = <SideNavAssociation pathname={pathname} />;
      }
    }

    canTimeout =
      this.props.user &&
      this.props.user.hasMembers &&
      !['super_admin', 'admin', 'almost_super'].includes(this.props.user.type) &&
      (['demo-client', 'dev-client', 'qa-client', 'localhost'].every(str => window.location.href.indexOf(str) == -1) || checkFeatureFlag("devCanTimeout")) &&
      ['login', 'ppo-calculator', 'my-optometrist', 'dentist-new'].every(str => pathname.indexOf(str) == -1);

    let timeoutDuration=15
    if(pathname.indexOf('office-enroll')!=-1
    ||pathname.indexOf('single-setup')!=-1
    ||pathname.indexOf('checkout')!=-1
    ||pathname.indexOf('multi-setup')!=-1
    ||pathname.indexOf('multi-csv-review')!=-1
    ){
      timeoutDuration=60
    }

    // TODO: HACK - should handle this more gracefully...
    if (
      user.type === 'client' ||
      pathname.indexOf('/signup/') === 0 ||
      pathname.indexOf('businessconnect') != -1
      // special case since it has a url param
    ) {
      canTimeout = false;
      wrapperStyle = 'wrapper';
      navbar = null;
    } else if (user.type === 'sm_business') {
      wrapperStyle =
        pathname.indexOf('/business/') != -1
          ? 'wrapper-dentist-new'
          : 'wrapper';
      navbar =
        pathname.indexOf('/business/') != -1 ? (
          <NavBarBusiness pathname={pathname} />
        ) : (
          <NavBar pathname={pathname} />
        );
      sidenavbar =
        pathname.indexOf('/business/') != -1 ? (
          <SideNavBusiness pathname={pathname} />
        ) : (
          ''
        );
    }

    return (
      <div>
        <div>
          {!this.state.removeElev &&
            user != false &&
            !footerBlackList.find((route) => pathname.includes(route)) && (
              <Elevio
                accountId="64f7d94574d2f"
                translations={{
                  help: 'Help Center',
                  modules: {
                    support: {
                      delayed_appearance: ' ',
                      create_new_ticket: 'How can we help?',
                      your_name: 'First Name',
                      surname: 'Last Name',
                    },
                  },
                }}
                user={{
                  first_name: user.firstName,
                  last_name: user.lastName,
                  email: user.email,
                  hash: user.elevioHash,
                }}
                keywords={['test']}
                onLoad={() => this.setState({ isLoaded: true })}
                onReady={() => this.setState({ isReady: true })}
                onWidgetOpened={() => this.setState({ isOpened: true })}
                onWidgetClosed={() => this.setState({ isOpened: false })}
              />
            )}
        </div>
        <div styleName={wrapperStyle}>
          {canTimeout && (
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              // sets the timeout for 15 minutes
              timeout={1000 * 60 * timeoutDuration} //
              onIdle={this.handleOnIdle}
              onAction={this.handleOnAction}
              eventsThrottle={200}
              debounce={250}
              startOnMount={true}
              stopOnIdle={true}
              crossTab={{
                emitOnAllTabs: true,
              }}
            />
          )}
          <LoadingBar
            scope="masterBar"
            style={{
              backgroundColor: '#00b7af',
              height: '15px',
              position: 'fixed',
              top: '3.7rem',
              zIndex: '100',
            }}
            progressIncrease={10}
          />
          {globalbanner}
          {navbar}
          {sidenavbar}
          {content}
          {canTimeout && (
            <TimeoutModal
              timeRemaining={5}
              onExpire={this.runLogout}
              onCancel={this.closeSessionTimeout}
              show={this.state.showSession}
            />
          )}
          {/* IdleModal for setup pages */}
          {(['demo-client', 'dev-client', 'qa-client', 'localhost'].every(str => window.location.href.indexOf(str) == -1) || checkFeatureFlag("devCanTimeout")) &&
             <IdleModal />}
        </div>
        <ResetZustandStores />
        <ResetCookies />
        <AppHelmet />
      </div>
    );
  }
}
