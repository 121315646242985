/*
Contact Us Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Modal from 'react-bootstrap/lib/Modal';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  submit as submitForm
} from 'redux-form';

// app
import LabeledInput from 'components/LabeledInput';
import Input from 'components/Input';
import LoadingSpinner from 'components/LoadingSpinner';

// local
import styles from './styles.module.css';
import ContactUsValidator from './validator';

/*
Redux
------------------------------------------------------------
*/
const mapDispatchToProps = (dispatch) => ({
  submit: () => dispatch(submitForm('contactUs')),
});


/*
Contact Us Form Modal
================================================================================
*/
@connect(null, mapDispatchToProps)
@reduxForm({
  form: 'contactUs',
  enableReinitialize: true,
  validate: ContactUsValidator,
})
@CSSModules(styles,{allowMultiple:true})
export default class ContactUsFormModal extends React.Component {

  static propTypes = {
    // form related
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,

    // modal related
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  };

  getLabeledInput(props) {
    return new LabeledInput(props);
  }

  getInput(props) {
    return new Input(props);
  }


  handleFormSubmit = (values) => {
    this.props.onSubmit(values);
  }
  /*
  Render
  ------------------------------------------------------------
  */
  render () {
    const {
      // form related
      handleSubmit,
      submit,
      submitting,

      // modal related
      show,
      onCancel,
    } = this.props;

    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onCancel}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>Need Assistance?</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
          <form
            className="form-horizontal"
          >
            <div className="row" styleName="hidden_stuff">
              <Field
                name="name"
                type="hidden"
                component={this.getLabeledInput}
                //label="Name"
                placeholder=""
                className="col-md-1"
              />

              <Field
                name="email"
                type="hidden"
                component={this.getLabeledInput}
                //label="Email"
                placeholder=""
                className="col-sm-1"
              />
            </div>
            {/* <span styleName="email">Please include the email address of the patient you are contacting us about</span> */}
            <div className="row">
              <Field
                name="message"
                type="textarea"
                component={this.getLabeledInput}
                label="Write Your Message"
                placeholder=""
                className="col-sm-12"
                rows={5}
              />
            </div>
          </form>
        </Modal.Body> 

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>
          <div className="row">

            <div className="col-md-9">
              <p>
                You can also email us at <a href="mailto:support@vision-hq.com" target="_blank">support@vision-hq.com</a> or give us a call at <a href="tel:(833) 222-4748">833-222-HQ4U (4748)</a>.
              </p>
            </div>

            <div className="col-md-3">
              <div className="modal-controls">
                <input
                  type="button"
                  className="modal-control"
                  disabled={submitting}
                  onClick={handleSubmit(this.handleFormSubmit)}
                  value={submitting ? 'SUBMITTING...':'SUBMIT'}
                />
              {submitting && <LoadingSpinner showOnlyIcon />}
              </div>
            </div>

          </div>
        </Modal.Footer>

      </Modal>
    );
  }
}
