/*
Admin Page Constants
================================================================================
*/

/* eslint-disable no-multi-spaces */

export const FETCH_DENTISTS_REQUEST = 'app/AdminDentistsPage/FETCH_DENTISTS_REQUEST';
export const FETCH_DENTISTS_SUCCESS = 'app/AdminDentistsPage/FETCH_DENTISTS_SUCCESS';
export const FETCH_DENTISTS_SUCCESS_ALL = 'app/AdminDentistsPage/FETCH_DENTISTS_SUCCESS_ALL';
export const FETCH_DENTISTS_ERROR   = 'app/AdminDentistsPage/FETCH_DENTISTS_ERROR';

export const FETCH_DENTIST_LIST_REQUEST = 'app/AdminDentistsPage/FETCH_DENTIST_LIST_REQUEST';
export const FETCH_DENTIST_LIST_SUCCESS = 'app/AdminDentistsPage/FETCH_DENTIST_LIST_SUCCESS';


export const FETCH_MEMBERS_REQUEST = 'app/AdminDentistsPage/FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'app/AdminDentistsPage/FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_SUCCESS_ALL = 'app/AdminDentistsPage/FETCH_MEMBERS_SUCCESS_ALL';
export const FETCH_MEMBERS_ERROR   = 'app/AdminDentistsPage/FETCH_MEMBERS_ERROR';

export const FETCH_DENTIST_DETAILS_REQUEST = 'app/AdminDentistsPage/FETCH_DENTIST_DETAILS_REQUEST';
export const FETCH_DENTIST_DETAILS_SUCCESS = 'app/AdminDentistsPage/FETCH_DENTIST_DETAILS_SUCCESS';
export const FETCH_DENTIST_DETAILS_ERROR   = 'app/AdminDentistsPage/FETCH_DENTIST_DETAILS_ERROR';

export const FETCH_DENTIST_MEMBERS_REQUEST = 'app/AdminDentistsPage/FETCH_DENTIST_MEMBERS_REQUEST';
export const FETCH_SINGLE_MEMBER_REQUEST = 'app/AdminDentistsPage/FETCH_SINGLE_MEMBER_REQUEST';
export const FETCH_DENTIST_MEMBERS_SUCCESS = 'app/AdminDentistsPage/FETCH_DENTIST_MEMBERS_SUCCESS';
export const FETCH_DENTIST_MEMBERS_ERROR   = 'app/AdminDentistsPage/FETCH_DENTIST_MEMBERS_ERROR';

export const FETCH_DENTIST_REPORTS_REQUEST = 'app/AdminDentistPage/FETCH_DENTIST_REPORTS_REQUEST';
export const FETCH_DENTIST_REPORTS_SUCCESS = 'app/AdminDentistPage/FETCH_DENTIST_REPORTS_SUCCESS';
export const FETCH_DENTIST_REPORTS_ERROR   = 'app/AdminDentistPage/FETCH_DENTIST_REPORTS_ERROR';

export const FETCH_MANAGER_REPORTS_REQUEST = 'app/AdminDentistPage/FETCH_MANAGER_REPORTS_REQUEST';
export const FETCH_MANAGER_REPORTS_SUCCESS = 'app/AdminDentistPage/FETCH_MANAGER_REPORTS_SUCCESS';
export const FETCH_MANAGER_REPORTS_ERROR   = 'app/AdminDentistPage/FETCH_MANAGER_REPORTS_ERROR';

export const FETCH_DENTIST_REVIEWS_REQUEST = 'app/AdminDentistsPage/FETCH_DENTIST_REVIEWS_REQUEST';
export const FETCH_DENTIST_REVIEWS_SUCCESS = 'app/AdminDentistsPage/FETCH_DENTIST_REVIEWS_SUCCESS';
export const FETCH_DENTIST_REVIEWS_ERROR   = 'app/AdminDentistsPage/FETCH_DENTIST_REVIEWS_ERROR';

export const FETCH_STATS_REQUEST = 'app/AdminDentistsPage/FETCH_STATS_REQUEST';
export const FETCH_STATS_SUCCESS = 'app/AdminDentistsPage/FETCH_STATS_SUCCESS';
export const FETCH_STATS_ERROR   = 'app/AdminDentistsPage/FETCH_STATS_ERROR';

export const SET_SELECTED_DENTIST = 'app/AdminDentistsPage/SET_SELECTED_DENTIST';
export const SET_SELECTED_MEMBER = 'app/AdminDentistsPage/SET_SELECTED_MEMBER';
export const SET_SELECTED_MANAGER = 'app/AdminDentistsPage/SET_SELECTED_MANAGER';

export const SEARCH   = 'app/AdminDentistsPage/SEARCH';
export const SORT     = 'app/AdminDentistsPage/SORT';

export const EDIT_DENTIST_REQUEST = 'app/AdminDentistPage/EDIT_DENTIST_REQUEST';
export const EDIT_DENTIST_SUCCESS = 'app/AdminDentistPage/EDIT_DENTIST_SUCCESS';
export const EDIT_DENTIST_ERROR   =  'app/AdminDentistPage/EDIT_DENTIST_ERROR';
export const SET_EDITING_DENTIST_ID = 'app/AdminDentistPage/SET_EDITING_DENTIST_ID';
export const REDIRECT_TO_ADMIN = 'app/AdminDentistPage/REDIRECT_TO_ADMIN';

export const DELETE_DENTIST_REVIEW_REQUEST = 'app/AdminDentistPage/DELETE_DENTIST_REVIEW_REQUEST';
export const DELETE_DENTIST_REVIEW_SUCCESS = 'app/AdminDentistPage/DELETE_DENTIST_REVIEW_SUCCESS';
export const DELETE_DENTIST_REVIEW_ERROR   = 'app/AdminDentistPage/DELETE_DENTIST_REVIEW_ERROR';

export const DOWNLOAD_REPORT_REQUEST = 'app/AdminDentistPage/DOWNLOAD_REPORT_REQUEST';
export const DOWNLOAD_REPORT_SUCCESS = 'app/AdminDentistPage/DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR   = 'app/AdminDentistPage/DOWNLOAD_REPORT_ERROR';

export const DOWNLOAD_MASTER_REPORT_REQUEST = 'app/AdminDentistPage/DOWNLOAD_MASTER_REPORT_REQUEST';
export const DOWNLOAD_MASTER_REPORT_SUCCESS = 'app/AdminDentistPage/DOWNLOAD_MASTER_REPORT_SUCCESS';
export const DOWNLOAD_MASTER_REPORT_ERROR   = 'app/AdminDentistPage/DOWNLOAD_MASTER_REPORT_ERROR';

export const FETCH_MANAGERS_REQUEST = 'app/AdminDentistsPage/FETCH_MANAGERS_REQUEST';
export const FETCH_MANAGERS_SUCCESS = 'app/AdminDentistsPage/FETCH_MANAGERS_SUCCESS';
export const FETCH_MANAGERS_ERROR   = 'app/AdminDentistsPage/FETCH_MANAGERS_ERROR';

export const FETCH_SUPPORTS_REQUEST = 'app/AdminDentistsPage/FETCH_SUPPORTS_REQUEST';
export const FETCH_SUPPORTS_SUCCESS = 'app/AdminDentistsPage/FETCH_SUPPORTS_SUCCESS';
export const FETCH_SUPPORTS_ERROR   = 'app/AdminDentistsPage/FETCH_SUPPORTS_ERROR';

export const TOGGLE_REFUNDING_MEMBER = 'app/AdminDentistsPage/TOGGLE_REFUNDING_MEMBER';
export const INITIATE_REFUNDING_MEMBER = 'app/AdminDentistsPage/INITIATE_REFUNDING_MEMBER';
export const REFUNDING_MEMBER_SUCCESS = 'app/AdminDentistsPage/REFUNDING_MEMBER_SUCCESS';
export const FAILED_REFUNDING_MEMBER = 'app/AdminDentistsPage/FAILED_REFUNDING_MEMBER';

export const TOGGLE_CHARGING_MEMBER = 'app/AdminDentistsPage/TOGGLE_CHARGING_MEMBER';
export const INITIATE_CHARGING_MEMBER = 'app/AdminDentistsPage/INITIATE_CHARGING_MEMBER';
export const CHARGING_MEMBER_SUCCESS = 'app/AdminDentistsPage/CHARGING_MEMBER_SUCCESS';
export const FAILED_CHARGING_MEMBER = 'app/AdminDentistsPage/FAILED_CHARGING_MEMBER';

export const TOGGLE_CHANGE_PLANS = 'app/AdminDentistsPage/TOGGLE_CHANGE_PLANS';
export const INITIATE_CHANGE_PLANS = 'app/AdminDentistsPage/INITIATE_CHANGE_PLANS';
export const TOGGLE_DRAWDATE_MEMBER = 'app/AdminDentistsPage/TOGGLE_DRAWDATE_MEMBER';
export const TOGGLE_BACKDATE_MEMBER = 'app/AdminDentistsPage/TOGGLE_BACKDATE_MEMBER';
export const INITIATE_DRAWDATE_MEMBER = 'app/AdminDentistsPage/INITIATE_DRAWDATE_MEMBER';
export const INITIATE_BACKDATE_MEMBER = 'app/AdminDentistsPage/INITIATE_BACKDATE_MEMBER';
export const DRAWDATE_MEMBER_SUCCESS = 'app/AdminDentistsPage/DRAWDATE_MEMBER_SUCCESS';
export const FAILED_DRAWDATE_MEMBER = 'app/AdminDentistsPage/FAILED_DRAWDATE_MEMBER';

export const FETCH_MASTER_REPORTS_DATES = 'app/AdminDentistsPage/FETCH_MASTER_REPORTS_DATES';
export const FETCH_MASTER_REPORTS_DATES_SUCCESS = 'app/AdminDentistsPage/FETCH_MASTER_REPORTS_DATES_SUCCESS';

export const TOGGLE_TRANSFERRING_MEMBER = 'app/AdminDentistsPage/TOGGLE_TRANSFERRING_MEMBER';
export const TRANSFER_MEMBER = 'app/AdminDentistsPage/TRANSFER_MEMBER';
export const TRANSFER_MEMBER_SUCCESS = 'app/AdminDentistsPage/TRANSFER_MEMBER_SUCCESS';
export const TRANSFER_MEMBER_FAILURE = 'app/AdminDentistsPage/TRANSFER_MEMBER_FAILURE';

export const TOGGLE_TERMS_UPDATE = 'app/AdminDentistsPage/TOGGLE_TERMS_UPDATE';
export const TERMS_UPDATE_REQUEST = 'app/AdminDentistsPage/TERMS_UPDATE_REQUEST';
export const TERMS_UPDATE_SUCCESS = 'app/AdminDentistsPage/TERMS_UPDATE_SUCCESS';
export const TERMS_UPDATE_ERROR = 'app/AdminDentistsPage/TERMS_UPDATE_ERROR';

export const TOGGLE_SECURITY_FORM = 'app/AdminDentistsPage/TOGGLE_SECURITY_FORM';
export const SECURITY_FORM_SUBMIT_REQUEST = 'app/AdminDentistsPage/SECURITY_FORM_SUBMIT_REQUEST';
export const SECURITY_FORM_SUBMIT_SUCCESS = 'app/AdminDentistsPage/SECURITY_FORM_SUBMIT_SUCCESS';
export const SECURITY_FORM_SUBMIT_ERROR = 'app/AdminDentistsPage/SECURITY_FORM_SUBMIT_ERROR';
export const UPDATE_GEOLOCATION = 'app/AdminDentistsPage/UPDATE_GEOLOCATION';
export const UNDO_STANDARD = 'app/AdminDentistsPage/UNDO_STANDARD';
export const REMOVE_BAA = 'app/AdminDentistsPage/REMOVE_BAA'
export const UPDATE_ONBOARDING = 'app/AdminDentistsPage/UPDATE_ONBOARDING';
export const UPDATE_NINETYDAY = 'app/AdminDentistsPage/UPDATE_NINETYDAY'
export const UPDATE_MARKETING_MATERIALS_PAID = 'app/AdminDentistsPage/UPDATE_MARKETING_MATERIALS_PAID';
export const UPDATE_BOX_SHIPPED = 'app/AdminDentistsPage/UPDATE_BOX_SHIPPED';
export const UPDATE_MEMBERSHIP_TRAINING = 'app/AdminDentistsPage/UPDATE_MEMBERSHIP_TRAINING';
export const UPDATE_BUSINESS_CONNECT_ON = 'app/AdminDentistsPage/UPDATE_BUSINESS_CONNECT_ON';
export const UPDATE_BUSINESS_CONNECT_TRAINING_ON = 'app/AdminDentistsPage/UPDATE_BUSINESS_CONNECT_TRAINING_ON';
export const UPDATE_AFFORDABILITY = 'app/AdminDentistsPage/UPDATE_AFFORDABILITY';

export const ADMIN_LOGIN_REQUEST = 'app/AdminDentistsPage/ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SPECIFIC_USER  = 'app/AdminDentistsPage/ADMIN_LOGIN_SPECIFIC_USER';
export const ADMIN_LOGIN_DIRECTLY_TO_MEMBER = 'app/AdminDentistsPage/ADMIN_LOGIN_DIRECTLY_TO_MEMBER'
export const MEMBER_LOGIN_REQUEST = 'app/AdminDentistsPage/MEMBER_LOGIN_REQUEST';

export const REFRESH_DENTISTS   = 'app/AdminDentistsPage/REFRESH_DENTISTS';
export const FETCH_MEMBERSHIPS_SUCCESS = 'app/AdminDentistsPage/FETCH_MEMBERSHIPS_SUCCESS';

export const DELETE_INCOMPLETE_OFFICE = 'app/AdminDentistsPage/DELETE_INCOMPLETE_OFFICE'
export const DELETE_INCOMPLETE_SUCCESS = 'app/AdminDentistsPage/DELETE_INCOMPLETE_SUCCESS'

export const FETCH_BOX_REPORTS = 'app/AdminDentistsPage/FETCH_BOX_REPORTS'
export const FETCH_BOX_REPORTS_SUCCESS = 'app/AdminDentistsPage/FETCH_BOX_REPORTS_SUCCESS'

export const   SET_COMPLETE_OFFICE = 'app/AdminDentistsPage/SET_COMPLETE_OFFICE'
export const SET_COMPLETE_SUCCESS ='app/AdminDentistsPage/SET_COMPLETE_SUCCESS'

export const TOGGLE_TO_CASH_MEMBER = 'app/AdminDentistsPage/TOGGLE_TO_CASH_MEMBER'
export const TO_CASH_MEMBER_SUCCESS = 'app/AdminDentistsPage/TO_CASH_MEMBER_SUCCESS'
export const FAILED_TO_CASH_MEMBER = 'app/AdminDentistsPage/FAILED_TO_CASH_MEMBER'
export const INITIATE_TO_CASH_MEMBER = 'app/AdminDentistsPage/INITIATE_TO_CASH_MEMBER'
export const IMPORT_OFFICE_TRANSACTIONS_REQUEST= 'app/AdminDentistsPage/IMPORT_OFFICE_TRANSACTIONS_REQUEST';


export const MOVE_MEMBER_TO_OWN_ACCOUNT_REQUEST = 'app/AdminDentistsPage/MOVE_MEMBER_TO_OWN_ACCOUNT_REQUEST'

export const TOGGLE_DELETION_REQUEST = 'app/AdminDentistsPage/TOGGLE_DELETION_REQUEST'

export const TOGGLE_ENROLL_NO_CHARGE = 'app/AdminDentistsPage/TOGGLE_ENROLL_NO_CHARGE'
export const INITIATE_ENROLL_NO_CHARGE = 'app/AdminDentistsPage/INITIATE_ENROLL_NO_CHARGE'

export const FETCH_PAUSED_DENTISTS = 'app/AdminDentistsPage/FETCH_PAUSED_DENTISTS'
export const FETCH_PAUSED_DENTISTS_SUCCESS = 'app/AdminDentistsPage/FETCH_PAUSED_DENTISTS_SUCCESS'

export const ADD_PAUSED_DENTISTS = 'app/AdminDentistsPage/ADD_PAUSED_DENTISTS'

export const FETCH_PROMOS_REQUEST = 'app/AdminDentistsPage/FETCH_PROMOS_REQUEST'
export const FETCH_PROMOS_SUCCESS = 'app/AdminDentistsPage/FETCH_PROMOS_SUCCESS'

export const ADD_PROMOS = 'app/AdminDentistsPage/ADD_PROMOS'
export const ADD_PROMOS_BY_REGULAR_ADMIN = 'app/AdminDentistsPage/ADD_PROMOS_BY_REGULAR_ADMIN'
export const EDIT_PROMOS = 'app/AdminDentistsPage/EDIT_PROMOS'
export const TOGGLE_MARKETING_MATERIALS_CHARGE = 'app/AdminDentistPage/TOGGLE_MARKETING_MATERIALS_CHARGE'

export const ADD_FAKE_OFFICES = 'app/AdminDentistPage/ADD_FAKE_OFFICES'
export const ADD_FAKE_MEMBERS = 'app/AdminDentistPage/ADD_FAKE_MEMBERS'

export const SWITCH_TO_NEW = 'app/AdminDentistPage/SWITCH_TO_NEW'