import { SelectOption } from 'new/components/Form/Select';

export const pmsPlanOptions: SelectOption[] = [
  { value: 'abelDent', label: 'ABELDent' },
  { value: 'carestack', label: 'CareStack' },
  { value: 'cloud9', label: 'Cloud 9' },
  { value: 'curve', label: 'Curve' },
  { value: 'datacon', label: 'Datacon' },
  { value: 'denticon', label: 'Denticon' },
  { value: 'dentimax', label: 'DentiMax' },
  { value: 'dentisoft', label: 'Dentisoft' },
  { value: 'dentrix', label: 'Dentrix' },
  { value: 'dentrixAscend', label: 'Dentrix Ascend' },
  { value: 'dentrixEnterprise', label: 'Dentrix Enterprise' },
  { value: 'dolphin', label: 'Dolphin' },
  { value: 'dovetail', label: 'Dovetail' },
  { value: 'doxPedo', label: 'DOX|Pedo' },
  { value: 'eaglesoft', label: 'Eaglesoft' },
  { value: 'iDentalSoft', label: 'iDentalSoft' },
  { value: 'openDental', label: 'Open Dental' },
  { value: 'practiceWeb', label: 'Practice-Web' },
  { value: 'practiceWorks', label: 'PracticeWorks' },
  { value: 'softdent', label: 'SoftDent' },
  { value: 'tab32', label: 'tab32' },
  { value: 'xlDent', label: 'XLDent' },
  { value: 'yapi', label: 'Yapi' },
  { value: 'other', label: 'Other' },
];

export const launchChecklistItems: Record<number, string> = {
  1: 'Finalize and activate your plans\n& pricing',
  2: 'Order Pricing Cards\n(first order is FREE!)',
  3: 'Watch <a href="https://dentalhq-uploads.s3.amazonaws.com/DHQ-TT.mp4" target="_blank">Team Training Video</a>',
  4: 'Additional questions? Email <a href="mailto:success@vision-hq.com" target="_blank">success@vision-hq.com</a>',
  5: 'Print and display QR Code signs',
  6: 'Send email to all patients to promote your Membership Program',
  7: 'Create call-to-action on your website linking to the Patient Sign-Up Page',
  8: 'Schedule first Monthly and Quarterly\nprogress calls',
};
