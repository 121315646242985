import React from 'react';
import Helmet from 'react-helmet';
import useActiveOffice from 'new/hooks/useActiveOffice';

import favIcon from 'assets/favicon_vision.ico';

const AppHelmet = () => {
  const { activeOfficeId, user } = useActiveOffice();

  const activeOffice = user?.data?.otherOffices?.find(
    (office) => String(office.id) === activeOfficeId,
  )
  const title =
    activeOffice ? `VHQ | ${activeOffice?.officeName}` : 'VisionHQ';

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/x-icon" href={favIcon} />
    </Helmet>
  );
};

export default AppHelmet;
