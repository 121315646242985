import { Tenant } from 'new/types/tenants';
import pngLogo from 'new/assets/logos/DHQ-ondark.png';
import pngLogoSmall from 'new/assets/logos/DHQ-small.png';
import pngLogoOnLight from 'new/assets/logos/DHQ-onlight.png';
import { ReactComponent as SvgLogo } from 'new/assets/logos/DHQ-ondark.svg';
import { ReactComponent as SvgLogoSmall } from 'new/assets/logos/DHQ-ondark-small.svg';
import { ReactComponent as SvgLogoOnLight } from 'new/assets/logos/DHQ-onlight.svg';
import loginBg from 'new/assets/login_bg.png';
import { launchChecklistItems, pmsPlanOptions } from '../constants/const.dhq';
import cardFront from 'new/assets/cardFront.jpg';
import cardBack from 'new/assets/cardBack.jpg';

const dhq: Tenant = {
  id: 'dhq',
  name: 'DentalHQ',
  shortName: 'DHQ',
  supportEmail: 'support@dentalhq.com',
  successEmail: 'success@dentalhq.com',
  features: {
    setupBaa: true,
    gpp: true,
    pms: true,
    marketingOnlyLinks: false,
    businessConnect: true,
    defaultSpecialty: null,
    upgradeOffice: true,
    seasonOfGivingWidget: true,
    feePerMember: true,
    leaderboardWidgetType: 'darkGreen',
  },
  constants: {
    launchRewards: {
      prize30: 50,
      prize60: 100,
      prize90: 150,
    },
    seasonOfGivingPrize: '$500, $250, and $125',
    seasonOfGivingImage:
      'https://dentalhq-files.s3.amazonaws.com/DHQ+-+Member+Magic+Rewards-03.png',
    marketingTemplates:
      'https://dentalhq-files.s3.amazonaws.com/Email+%26+Text+Blasts+to+Patients+(Template)+.docx',
    marketingQrPdf1: 'https://dentalhq-files.s3.amazonaws.com/PDFQR1.png',
    marketingQrPdf2: 'https://dentalhq-files.s3.amazonaws.com/PDFQR2.png',
    marketingQrPdf3: 'https://dentalhq-files.s3.amazonaws.com/PDFQR3.png',
    marketingQrPdf4: 'https://dentalhq-files.s3.amazonaws.com/PDFQR4.png',
    marketingQrPdf5: 'https://dentalhq-files.s3.amazonaws.com/PDFQR5.png',
    ucrPrefix: 'd',
    themePreview1:
      'https://s3.amazonaws.com/market_materials/get-started-preview1.png',
    themePreview2:
      'https://s3.amazonaws.com/market_materials/get-started-preview2.png',
    themePreview3:
      'https://s3.amazonaws.com/market_materials/get-started-preview3.png',
    login: {
      fontFamilyContainer: 'Epilogue',
      fontFamilyTitle: 'Termina',
    },
    externalPatientLandingBaseUrl: '/accounts/signup/my-dentist',
    pmsPlanOptions,
    launchChecklistItems,
    defaultMonthlyPrice: 49,
    defaultYearlyPrice: 499,
    files: {
      scriptingPdf:
        'https://dentalhq-files.s3.amazonaws.com/DHQ+Scripting+(2).pdf',
      quickstartGuidePdf: '',
      memberSignaturePdf:
        'https://dentalhq-uploads.s3.amazonaws.com/DMP_In-office_Agreement_(generic).pdf',
      termsAndConditions:
        'Dental Membership Plan Member - Terms & Conditions_July 2024.pdf',
      termsAndConditionsSpanish:
        'https://dentalhq-uploads.s3.amazonaws.com/Dental+Membership+Plan+-+Terms+%26+Conditions_July+2024_ES-MX.pdf',
    },
  },
  logos: {
    pngLogo,
    pngLogoSmall,
    pngLogoOnLight,
    SvgLogo,
    SvgLogoSmall,
    SvgLogoOnLight,
  },
  assets: {
    loginBg,
    printMaterialsFront: cardFront,
    printMaterialsBack: cardBack,
  },
  theme: {
    login: {
      buttonBg: '#00A1DF',
      color: '#024C64',
    },
    modal: {
      fontFamily: 'Barlow',
    },
    rocketRight: -8,
    rocketTop: 105,
  },
  socialSlides: [
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic---1.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic---2.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic---3.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic---4.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-5.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-6.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-7.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-8.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-9.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-10.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-11.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-12.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-13.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-14.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-15.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-20.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-21.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-22.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-23.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-24.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-25.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-26.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-27.png',
    },
    {
      image:
        'https://dentalhq-files.s3.amazonaws.com/marketing/DHQ---FB-social-graphic-28.png',
    },
  ],
  awsStorageUrl: 'https://market_materials.s3.amazonaws.com',
};

export default dhq;
