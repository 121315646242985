/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import Navbar from 'react-bootstrap/lib/Navbar';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// app

import { selectCurrentUser } from 'containers/App/selectors';
import FaTimesCircleO from 'react-icons/lib/fa/times-circle-o';

// local
import styles from './styles.module.css';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps (state) {
  return {
    loggedInUser: selectCurrentUser(state),
    dentist_logo: state.smBusinessSignUp && state.smBusinessSignUp.accountInfo ?state.smBusinessSignUp.accountInfo.dentist_logo: (state.completeBusinessSignupPage && state.completeBusinessSignupPage.dentist_logo ? state.completeBusinessSignupPage.dentist_logo : null)
  };
}


/*
NavBar
================================================================================
*/
@connect(mapStateToProps, null)
@CSSModules(styles, { allowMultiple: true })
export default class GlobalBanner extends React.Component {

  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    pathname: PropTypes.string,
    globalBannerInfo:  PropTypes.object,
    logo: PropTypes.oneOfType([
      PropTypes.string, // show custom logo
      PropTypes.bool,   // false => show no logo (i.e. custom logo url is loading)
    ]),                       // null => show VisionHQ logo
  };

  hideBanner=()=>{
    cookies.set('banner_hidden', true, { path: '/' });
  }

  render () {
    const {
      globalBannerInfo
    } = this.props;

    return (
      <div styleName="globalbanner">
          <span styleName="headline">{globalBannerInfo.message}</span>
          <a href={globalBannerInfo.callToActionLink} target="_blank" styleName="cta"> {globalBannerInfo.callToAction}
        </a>
      </div>
    );
  }
}
