import { SelectOption } from 'new/components/Form/Select';

export const pmsPlanOptions: SelectOption[] = [
  { value: 'officemate', label: 'OfficeMate' },
  { value: 'crystal', label: 'Crystal' },
  { value: 'revolution', label: 'Revolution' },
  { value: 'compulink', label: 'Compulink' },
  { value: 'other', label: 'Other' },
];

export const launchChecklistItems: Record<number, string> = {
  1: 'Finalize and activate your plans & pricing',
  2: '<a href="/account/marketing/">Print and display QR Code signs</a>',
  3: 'Send email and text blasts to all patients to promote your Membership Program',
  4: 'Create call-to-action on your website linking to the Patient Sign-Up Page',
  5: 'Additional questions? Email \n<a href="mailto:success@vision-hq.com" target="_blank">success@vision-hq.com</a>',
  6: 'Watch <a href="https://visionsource-uploads.s3.us-east-2.amazonaws.com/7.22.24+VHQ+TT.mp4" target="_blank">Team Training Video</a>',
};
