/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import FaHome from 'react-icons/lib/fa/home';
import FaPlus from 'react-icons/lib/fa/plus';
import FaFile from 'react-icons/lib/fa/file';
import FaWrench from 'react-icons/lib/fa/wrench';
import FaDollar from 'react-icons/lib/fa/dollar';
import FaBriefcase from 'react-icons/lib/fa/briefcase';
import FaUsers from 'react-icons/lib/fa/group';
import FaLifeBouy from 'react-icons/lib/fa/life-bouy';
import PaymentPlanModal from 'components/PaymentPlanModal';
import ElevateModal from 'components/ElevateModal';
import AddLocationModal from 'components/AddLocationModal';
import { toast } from 'react-toastify';
import ContactUsFormModal from 'components/ContactUsFormModal';
import moment from 'moment';
import { LEGACY_ACCOUNTS } from 'common/constants';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons';

const PDF_URL = 'https://dentalhq-uploads.s3.amazonaws.com/In-Office-Enrollment-Form-v2.pdf';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

import InviteModal from 'components/InviteModal';
import ShowStripeModal from 'components/ShowStripeModal';
import ShowResetStripeModal from 'components/ShowResetStripeModal';
import { getItem, removeItem } from 'utils/localStorage';

// app
import { selectCurrentUser } from 'containers/App/selectors';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// local
import styles from './styles.module.css';

import {
  // fetch
  fetchDentistInfo,
  fetchDentistReports,
  fetchStats,
  fetchPatients,
  setEditingPaymentPlans,
  setElevate,
  setAddLocation,
  clearAddLocation,
  invitePatient,
  clearEditingPaymentPlans,
  clearElevate,
  submitPaymentPlansForm,
  addVaridi,
  gargleVaridi,
  switchToStep,
  removeStandard,
  submitContactUsForm,
  clearContactUsMessage,
} from '../../containers/dashboardUpdate/DentistMembersPage/actions';
import {
  // fetch
  selectDataLoaded,
  selectDentistInfo,
  selectStats,
  selectProcessedPatients,
  selectDentistReports,

  // search / filter patients
  selectMemberSearchTerm,
  selectMemberFilterTerm,

  // add / edit member
  selectEditingMember,

  // edit patient profile
  selectEditingPatientProfile,

  // edit patient payment info
  selectEditingPatientPayment,

  // edit security
  editingSecuritySelector,
  editingPaymentPlansSelector,
  editingElevateSelector,
  addLocationsSelector,
  dentistRatingSelector,
} from '../../containers/dashboardUpdate/DentistMembersPage/selectors';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps(state) {
  return {
    loggedInUser: selectCurrentUser(state),
    dentistInfo: selectDentistInfo(state),
    reports: selectDentistReports(state),
    user: selectCurrentUser(state),
    editingPaymentPlans: editingPaymentPlansSelector(state),
    editingElevate: editingElevateSelector(state),
    addingLocation: addLocationsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeRoute: (url) => dispatch(push(url)),
    fetchDentistInfo: (dentistInfoId) =>
      dispatch(fetchDentistInfo(dentistInfoId)),
    fetchDentistReports: (dentistInfoId) =>
      dispatch(fetchDentistReports(dentistInfoId)),
    fetchStats: (dentistInfoId) => dispatch(fetchStats(dentistInfoId)),
    fetchPatients: (dentistInfoId) => dispatch(fetchPatients(dentistInfoId)),
    setEditingPaymentPlans: (dentistInfoId) =>
      dispatch(setEditingPaymentPlans(dentistInfoId)),
    setElevate: (dentistInfoId) => dispatch(setElevate(dentistInfoId)),
    clearEditingPaymentPlans: () => dispatch(clearEditingPaymentPlans()),
    clearElevate: () => dispatch(clearElevate()),
    setAddLocation: (dentistInfoId) => dispatch(setAddLocation(dentistInfoId)),
    clearAddLocation: () => dispatch(clearAddLocation()),
    submitPaymentPlansForm: (values, user) =>
      dispatch(submitPaymentPlansForm(values, user)),
    invitePatient: (payload) => dispatch(invitePatient(payload)),

    addVaridi: (dentistInfoId) => dispatch(addVaridi(dentistInfoId)),
    gargleVaridi: (dentistInfoId) => dispatch(gargleVaridi(dentistInfoId)),
    submitContactUsForm: (payload) => dispatch(submitContactUsForm(payload)),
    switchToStep: (dentistInfoId, step) =>
      dispatch(switchToStep(dentistInfoId, step)),
    removeStandard: (dentistInfoId) => dispatch(removeStandard(dentistInfoId)),
  };
}

/*
NavBar
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class SideNavDashboard extends React.Component {
  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    pathname: PropTypes.string,
    logo: PropTypes.oneOfType([
      PropTypes.string, // show custom logo
      PropTypes.bool, // false => show no logo (i.e. custom logo url is loading)
    ]), // null => show VisionHQ logo
    currentSearchTerm: PropTypes.string,
    dentistInfo: PropTypes.object,
    stats: PropTypes.array,
    reports: PropTypes.object,
    user: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

    // passed in - actions
    onMemberSearch: PropTypes.func,
    onMemberInvited: PropTypes.func,
    onReportSelected: PropTypes.func,
    onSecurityLinkClicked: PropTypes.func,
    onPaymentPlanLinkClicked: PropTypes.func,
    setEditingPaymentPlans: PropTypes.func.isRequired,
    clearEditingPaymentPlans: PropTypes.func.isRequired,
    setElevate: PropTypes.func.isRequired,
    clearElevate: PropTypes.func.isRequired,
    setAddLocation: PropTypes.func.isRequired,
    clearAddLocation: PropTypes.func.isRequired,
    addVaridi: PropTypes.func.isRequired,
    submitContactUsForm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      memberSearchTerm:
        this.props.currentSearchTerm !== null
          ? this.props.currentSearchTerm
          : '',
      showReportsModal: false,
      showInvitePatientModal: false,
      showStripeModal: false,
      showResetStripeModal: false,
      showContactUsModal: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user) {
      if (this.props.user.otherOffices.length == 1)
        cookies.set('officeId', this.props.user.dentistInfo.id, { path: '/' });
    }

    this.setState({
      dialog: {},
      showingStripe: true,
      memberSearchTerm:
        this.props.currentSearchTerm !== null
          ? this.props.currentSearchTerm
          : '',
      showReportsModal: false,
      showInvitePatientModal: false,
      showStripeModal: false,
      showResetStripeModal: false,
    });
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleVaridiActivate = () => {
    this.props.addVaridi(this.props.user.dentistInfo.id);
  };

  handleGargleVaridi = () => {
    this.props.gargleVaridi(this.props.user.dentistInfo.id);
  };

  // componentDidUpdate(prevProps){
  //   if(prevProps.dentistInfo!=this.props.dentistInfo){
  //     if(cookies.get('officeId')!=""&&this.props.dentistInfo.stripeAccountId!=null&&this.props.dentistInfo.daoi.standardStripeDateConverted==null&&this.props.dentistInfo.daoi.standardStripeAccountId!=null&&this.props.pathname.indexOf('dentist-new/members')!=-1&&this.props.pathname.indexOf('memberships')==-1&&LEGACY_ACCOUNTS.indexOf(this.props.dentistInfo.id)==-1){
  //       this.setState({...this.state,showResetStripeModal:true})
  //     }
  //     // if(cookies.get('officeId')!=""&&this.props.dentistInfo.stripeAccountId==null&&this.props.dentistInfo.daoi.standardStripeAccountId==null&&this.props.pathname.indexOf('dentist-new/members')!=-1&&this.props.pathname.indexOf('memberships')==-1&&LEGACY_ACCOUNTS.indexOf(this.props.dentistInfo.id)==-1){
  //     //   this.setState({...this.state,showStripeModal:true})
  //     // }
  //   }
  // }

  onMemberInvitedSideNav = (data) => {
    this.props.invitePatient({
      dentistInfoId: this.props.dentistInfo.id,
      email: data.email,
      officeName: this.props.dentistInfo.officeName,
      officeEmail: this.props.dentistInfo.email,
      officeLogo: this.props.dentistInfo.logo,
      spanish: data.spanish,
    });
    this.setState({
      ...this.state,
      showInvitePatientModal: false,
    });
  };

  printEnrollment = () => {
    const pdf = window.open(PDF_URL);
    pdf.print();
  };

  returnLinks = {
    '/accounts/dentist-signup': (
      <Link to="/" styleName="navbar__text">
        &lt; Home
      </Link>
    ),
    '/accounts/login': (
      <Link to="/accounts/login" styleName="navbar__text">
        &lt; Login
      </Link>
    ),
  };

  cancelPaymentPlansFormAction = () => {
    this.props.clearEditingPaymentPlans();
  };

  cancelElevateAction = () => {
    this.props.clearElevate();
  };

  onPaymentPlans = (dentistInfoId) => {
    if (this.props.user.dentistVaridi) {
      window.open(`/api/v1/varidi-connect/login/${this.props.dentistInfo.id}`);
    } else this.props.setEditingPaymentPlans(dentistInfoId);
  };

  onElevate = (dentistInfoId) => {
    this.props.setElevate(dentistInfoId);
  };

  onAddLocation = (dentistInfoId) => {
    this.props.setAddLocation(dentistInfoId);
  };

  cancelAddLocation = () => {
    this.props.clearAddLocation();
  };

  handleContactUsFormSubmit = (values) => {
    this.props.submitContactUsForm(values);
  };

  selectTab = (key) => {
    if (cookies.get('finishedAndPaid') == 'false') {
      let finished = confirm(
        'You have not completed payment. Are you sure you want to leave before clicking "Finish & Pay"?',
      );
      key = finished == false ? null : key;
      if (finished == true) cookies.remove('finishedAndPaid', { path: '/' });
    }
    switch (key) {
      case 'contact-admin':
        this.props.changeRoute('/dentist-new/contact-admin');
        break;
      case 'business-connect':
        if (this.props.dentistInfo.daoi.businessConnectOn == true) {
          this.props.changeRoute('/dentist-new/business-connect');
        } else {
          this.props.changeRoute('/dentist-new/landing-connect');
        }
        break;
      case 'community-connect':
        this.props.changeRoute('/dentist-new/community-connect');
        break;
      case 'business-connect-reports':
        this.props.changeRoute('/dentist-new/business-connect-reports');
        break;
      case 'custom':
        this.props.changeRoute('/dentist-new/memberships');
        break;
      case 'edit-profile':
        this.props.changeRoute('/dentist-new/edit-profile');
        break;
      case 'members':
        this.props.changeRoute('/dentist-new/members');
        break;
      case 'add-members':
        this.props.changeRoute('/dentist-new/signup');
        break;
      case 'reports':
        this.props.changeRoute('/dentist-new/reports');
        break;
      case 'settings':
        this.props.changeRoute('/dentist-new/profile');
        break;
      case 'users':
        this.props.changeRoute('/dentist-new/users');
        break;
      case 'enrollment':
        this.props.changeRoute('/dentist-new/signup');
        break;
      case 'marketing':
        this.props.changeRoute('/dentist-new/marketing');
        break;
      case 'activity':
        this.props.changeRoute('/dentist-new/activity');
        break;
      case 'payment-ledger':
        this.props.changeRoute('/dentist-new/payment-ledger');
        break;
      case 'multiLocationReports':
        this.props.changeRoute('/dentist-new/multiLocationReports');
        break;
      default:
        // Don't do anything... we should never actually reach here.
        break;
        
    }
  };

  /*
  Component Actions
  ------------------------------------------------------------
  */

  handleCloseDialog() {
    this.setState({ dialog: {} });
  }

  onMemberSearch = () => {
    this.props.onMemberSearch(this.state.memberSearchTerm);
  };

  updateMemberSearchTerm = (evt) => {
    this.setState({
      ...this.state,
      memberSearchTerm: evt.target.value,
    });
    if (evt.target.value == '') this.onMemberSearch();
  };

  toggleReportsModal = () => {
    this.setState({
      ...this.state,
      showReportsModal: !this.state.showReportsModal,
    });
  };

  toggleInvitePatientModal = () => {
    this.setState({
      ...this.state,
      showInvitePatientModal: !this.state.showInvitePatientModal,
    });
  };

  toggleStripeModal = () => {
    this.setState({
      ...this.state,
      showStripeModal: !this.state.showStripeModal,
    });
  };

  toggleResetStripeModal = () => {
    this.setState({
      ...this.state,
      showResetStripeModal: !this.state.showResetStripeModal,
    });
  };

  stripeSetup = () => {
    this.props.switchToStep(this.props.dentistInfo.id, 4);
    window.location =
      '/accounts/complete-dentist-signup/' +
      this.props.dentistInfo.id +
      '/' +
      this.props.dentistInfo.activationKey;
  };

  stripeResetSetup = () => {
    this.props.removeStandard(this.props.dentistInfo.id);
    window.location =
      '/accounts/complete-dentist-signup/' +
      this.props.dentistInfo.id +
      '/' +
      this.props.dentistInfo.activationKey;
  };

  updateSearchTerm = (evt) => {
    if (evt.key == 'Enter') this.onMemberSearch();
  };

  copyOfficeLink = (e) => {
    var inputtext = document.querySelector('#copyText');
    inputtext.select();
    document.execCommand('copy');
    toast.success(
      'Copied to clipboard: ' + document.getElementById('copyText').value,
    );
  };

  toggleHelpModal = () => {
    this.setState({
      ...this.state,
      showContactUsModal: !this.state.showContactUsModal,
    });
  };
  render() {
    const {
      logo,
      dentistLink,
      dentistInfo,
      patients,
      stats,
      reports,
      user,
      pathname,
      onMemberInvited,
      onReportSelected,
      onSecurityLinkClicked,
      onPaymentPlanLinkClicked,
      editingPaymentPlans,
      editingElevate,
      addingLocation,
      dentistRating,
    } = this.props;
    const { firstName, lastName, avatar } = this.props.loggedInUser;
    let invitetooltip = (
      <Tooltip id="tooltip">
        This feature will become available upon completion of Initial
        Onboarding.
      </Tooltip>
    );
    let disabledtooltip = (
      <Tooltip id="tooltip">
        This feature is currently disabled. Please contact VisionHQ if you have
        any questions.
      </Tooltip>
    );
    //let invitetooltip = <Tooltip id="tooltip">Coming Soon</Tooltip>;

    const {
      memberSearchTerm,
      showReportsModal,
      showInvitePatientModal,
      showStripeModal,
      showResetStripeModal,
    } = this.state;

    const jwt = getItem('auth_token');

    const fullName = `${firstName} ${lastName}`;
    const returnLink = this.returnLinks.hasOwnProperty(this.props.pathname)
      ? this.returnLinks[this.props.pathname]
      : null;

    let editingContactUsMessage = {
      name: this.props.user.firstName + ' ' + this.props.user.lastName,
      email: this.props.user.email,
      message: null,
    };

    //console.log(editingPaymentPlans)
    // else - show no logo

    return (
      <div
        styleName={
          this.props.withbanner == true ? 'sidebar withbanner' : 'sidebar'
        }
      >
        {((this.props.dentistInfo != undefined &&
          this.props.dentistInfo.memberships.length == 0) ||
          (this.props.dentistInfo != undefined &&
            this.props.dentistInfo.tieredPayment?.tierLevel == 0 &&
            this.props.dentistInfo.tieredPayment?.stripeCustomerId != null) ||
          this.props.dentistInfo == undefined  ) && (
          <div styleName="sidebar_disabled"></div>
        )}
        <Accordion>
          <AccordionItem onClick={() => this.selectTab('members')}>
            <AccordionItemTitle
              styleName={
                this.props.pathname.indexOf('dentist-new/members') != -1 &&
                this.props.pathname.indexOf('memberships') == -1
                  ? 'on_dashboard'
                  : ''
              }
            >
              <FaHome />
              &emsp;Dashboard
            </AccordionItemTitle>
          </AccordionItem>

          {cookies.get('officeId') != '' &&
            this.props.dentistInfo &&
            this.props.dentistInfo.verified == true &&
            this.props.dentistInfo.daoi.accountStatus != 3 && (
              <AccordionItem onClick={() => this.selectTab('add-members')}>
                <AccordionItemTitle>
                  <FaPlus />
                  &emsp;Add Member
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <ul>
                    <li>
                      {dentistInfo != undefined && (
                        <a
                          onClick={() => this.selectTab('enrollment')}
                          aria-selected={
                            this.props.pathname.indexOf('signup') != -1
                              ? 'true'
                              : 'false'
                          }
                        >
                          - Enrollment Form
                        </a>
                      )}
                    </li>
                    {this.props.dentistInfo &&
                      (dentistInfo.stripeAccountId != null ||
                        LEGACY_ACCOUNTS.indexOf(dentistInfo.id) != -1) && (
                        <li>
                          <a
                            onClick={this.toggleInvitePatientModal}
                            id="invite_patient"
                          >
                            - Invite Patient
                          </a>
                        </li>
                      )}
                    <li>
                      {dentistInfo != undefined && (
                        <a onClick={this.printEnrollment}>
                          - Printable Enrollment Form
                        </a>
                      )}
                    </li>
                  </ul>
                </AccordionItemBody>
              </AccordionItem>
            )}
          {cookies.get('officeId') != '' &&
            this.props.dentistInfo &&
            this.props.dentistInfo.verified == false && (
              <AccordionItem>
                <AccordionItemTitle styleName="cc_disabled">
                  <FaPlus />
                  &emsp;Add Member
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
              </AccordionItem>
            )}

          {this.props.dentistInfo && (
            <AccordionItem
              onClick={() => this.selectTab('business-connect')}
              styleName="beta"
            >
              <AccordionItemTitle>
                <FaBriefcase />
                &emsp;BusinessConnect&trade;
                <div className="accordion__arrow" role="presentation" />
              </AccordionItemTitle>
            </AccordionItem>
          )}
          
          {this.props.dentistInfo &&
            this.props.dentistInfo.daoi.onboardingDateComplete != null && (
              <AccordionItem
                onClick={() => this.selectTab('community-connect')}
                styleName="beta"
              >
                <AccordionItemTitle>
                  <FaUsers />
                  &emsp;CommunityConnect&trade;
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
              </AccordionItem>
            )}

          {this.props.dentistInfo &&
            this.props.dentistInfo.daoi.onboardingDateComplete == null && (
              <AccordionItem styleName="beta">
                <OverlayTrigger placement="top" overlay={invitetooltip}>
                  <AccordionItemTitle styleName="cc_disabled">
                    <FaUsers />
                    &emsp;CommunityConnect&trade;
                    <div className="accordion__arrow" role="presentation" />
                  </AccordionItemTitle>
                </OverlayTrigger>
              </AccordionItem>
            )}
          <AccordionItem>
            <AccordionItemTitle>
              <FaDollar />
              &emsp;Financial Solutions
              <div className="accordion__arrow" role="presentation" />
            </AccordionItemTitle>
            <AccordionItemBody>
              <ul>
                <li>
                  <a onClick={this.onPaymentPlans}>- Guaranteed Payment Plan</a>
                </li>
                {/* <li>
                        <a onClick={this.onElevate}>
                      - Remote Billing
                      </a>
                    </li> */}
              </ul>
            </AccordionItemBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemTitle>
              <FaFile />
              &emsp;
              <a
                onClick={() => this.selectTab('activity')}
                aria-selected={
                  this.props.pathname.indexOf('activity') != -1
                    ? 'true'
                    : 'false'
                }
              >
                Activity
              </a>
            </AccordionItemTitle>
          </AccordionItem>
          {/* {cookies.get('beccascookie')&&(
              <AccordionItem>
                <AccordionItemTitle>
                    <FaFile/>&emsp;Reports
                </AccordionItemTitle>
                <AccordionItemBody>
                <ul>
                <li>
              <a onClick={() =>this.selectTab('reports')} aria-selected={this.props.pathname.indexOf('reports')!=-1?"true":"false"}>
                  - Payouts
                </a>
              </li>
                <li>
              <a onClick={() =>this.selectTab('payment-ledger')} aria-selected={this.props.pathname.indexOf('payment-ledger')!=-1?"true":"false"}>
                  - Payment Ledger
                </a>
               </li>
              {cookies.get('officeId')!=""&&this.props.dentistInfo&&this.props.dentistInfo.stripeAccountId&&(
              <li>
              <a onClick={() =>this.selectTab('business-connect-reports')} >
                 -Business Reports
              </a>
              </li>
              )}}

                  </ul>
                  </AccordionItemBody>
                </AccordionItem>
            )} */}
          {user != undefined &&
            (user.type == 'dentist' || user.type == 'office_admin') && (
              <AccordionItem>
                <AccordionItemTitle>
                  <FaWrench />
                  &emsp;Manage
                  <div className="accordion__arrow" role="presentation" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <ul>
                    {cookies.get('officeId') != '' && this.props.dentistInfo && (
                      <li>
                        <a
                          onClick={() => this.selectTab('custom')}
                          aria-selected={
                            this.props.pathname.indexOf('memberships') != -1
                              ? 'true'
                              : 'false'
                          }
                        >
                          - Membership Plans
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        onClick={() => this.selectTab('marketing')}
                        aria-selected={
                          this.props.pathname.indexOf('marketing') != -1
                            ? 'true'
                            : 'false'
                        }
                      >
                        - Marketing Portal
                      </a>
                    </li>
                    {cookies.get('officeId') != '' && this.props.dentistInfo && (
                      <li>
                        <a
                          onClick={() => this.selectTab('edit-profile')}
                          aria-selected={
                            this.props.pathname.indexOf('edit-profile') != -1
                              ? 'true'
                              : 'false'
                          }
                        >
                          - Edit Office Profile
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        onClick={() => this.selectTab('users')}
                        aria-selected={
                          this.props.pathname.indexOf('users') != -1
                            ? 'true'
                            : 'false'
                        }
                      >
                        - Users
                      </a>
                    </li>
                    {cookies.get('officeId') != '' &&
                      this.props.dentistInfo &&
                      !this.props.dentistInfo.stripeAccountId &&
                      !this.props.dentistInfo.daoi.standardStripeAccountId && (
                        <li>
                          <a onClick={this.toggleStripeModal}>
                            - Stripe Account
                          </a>
                        </li>
                      )}
                    {cookies.get('officeId') != '' &&
                      this.props.dentistInfo &&
                      !this.props.dentistInfo.stripeAccountId &&
                      this.props.dentistInfo.daoi.standardStripeAccountId && (
                        <li>
                          <a onClick={this.toggleResetStripeModal}>
                            - Connect Bank Account
                          </a>
                        </li>
                      )}
                    {cookies.get('officeId') != '' &&
                      this.props.dentistInfo &&
                      this.props.dentistInfo.stripeAccountId &&
                      this.props.dentistInfo.last4 == null && (
                        <li>
                          <a
                            href={
                              '/api/v1/stripe-connect/viewAccount?jwt=' + jwt
                            }
                            target="_blank"
                          >
                            - Stripe Account
                          </a>
                        </li>
                      )}
                    {cookies.get('officeId') != '' &&
                      this.props.dentistInfo &&
                      this.props.dentistInfo.stripeAccountId != null &&
                      this.props.dentistInfo.stripeAccountId ==
                        this.props.dentistInfo.daoi.standardStripeAccountId &&
                      this.props.dentistInfo.daoi.standardStripeDateConverted ==
                        null && (
                        <li>
                          <a onClick={this.toggleResetStripeModal}>
                            - I'm Ready to Upgrade
                          </a>
                        </li>
                      )}
                    {/* <li>
                <a onClick={() =>this.selectTab('reviews')} aria-selected={this.props.pathname.indexOf('reviews')!=-1?"true":"false"}>
                 - Reviews
                </a>
              </li> */}
                    <li>
                        <span>
                        <a onClick={this.onAddLocation}>- Locations
                          </a>
                        </span>
                    </li>
                  </ul>
                </AccordionItemBody>
              </AccordionItem>
            )}
          {cookies.get('beccascookie') &&
            this.props.dentistInfo &&
            this.props.dentistInfo.stripeAccountId && (
              <AccordionItem>
                <AccordionItemTitle>
                  <a
                    href={`https://dashboard.stripe.com/${this.props.dentistInfo.stripeAccountId}`}
                    target="_blank"
                  >
                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  </a>
                </AccordionItemTitle>
              </AccordionItem>
            )}
          {cookies.get('beccascookie') &&
            this.props.dentistInfo &&
            this.props.dentistInfo.daoi.standardStripeAccountId && (
              <AccordionItem>
                <AccordionItemTitle>
                  <a
                    href={`https://dashboard.stripe.com/${this.props.dentistInfo.daoi.standardStripeAccountId}`}
                    target="_blank"
                  >
                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  </a>
                </AccordionItemTitle>
              </AccordionItem>
            )}
            

          <PaymentPlanModal
            show={editingPaymentPlans !== null}
            onActivate={this.handleVaridiActivate}
            gargleVaridi={this.handleGargleVaridi}
            onCancel={this.cancelPaymentPlansFormAction}
            dentist={user}
            initialValues={editingPaymentPlans}
          />

          <ElevateModal
            show={editingElevate !== null}
            onActivate={this.handleElevateActivate}
            onCancel={this.cancelElevateAction}
            dentist={user}
            initialValues={editingElevate}
          />

          <AddLocationModal
            show={addingLocation !== null}
            onCancel={this.cancelAddLocation}
            duplicateUrl={
              this.props.user
                ? '/api/v1/accounts/duplicate/' +
                  this.props.user.firstOffice +
                  '/' +
                  this.props.user.userId
                : ''
            }
            user={user}
            initialValues={addingLocation}
          />
        </Accordion>

        <ShowStripeModal
          show={showStripeModal}
          onHide={this.toggleStripeModal}
          onSubmit={this.stripeSetup}
        />

        <ShowResetStripeModal
          show={showResetStripeModal}
          onHide={this.toggleResetStripeModal}
          onSubmit={this.stripeResetSetup}
        />

        <InviteModal
          show={showInvitePatientModal}
          onHide={this.toggleInvitePatientModal}
          initialValues={{ spanish: 'false' }}
          inviteTarget={'patient'}
          onSubmit={this.onMemberInvitedSideNav}
        />

        <ContactUsFormModal
          show={this.state.showContactUsModal}
          onCancel={this.toggleHelpModal}
          initialValues={editingContactUsMessage}
          onSubmit={this.handleContactUsFormSubmit}
        />
        {/* <div styleName="help" onClick={this.toggleHelpModal}>
     <FaLifeBouy/> Help
     </div> */}
      </div>
    );
  }
}
