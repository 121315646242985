/*
ShowStripe Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Field, reduxForm, submit as submitForm } from 'redux-form';

// app
import LabeledInput from 'components/LabeledInput';

// local
import styles from './styles.module.css';
import ShowStripeFormValidator from './validator';

/*
Redux
------------------------------------------------------------
*/
const mapDispatchToProps = (dispatch) => ({
  submit: () => dispatch(submitForm('ShowStripe')),
});

/*
ShowStripe Form Modal
================================================================================
*/
@connect(null, mapDispatchToProps)
@reduxForm({
  form: 'ShowStripe',
  enableReinitialize: true,
  validate: ShowStripeFormValidator,
})
@CSSModules(styles,{allowMultiple:true})
export default class ShowStripeModal extends React.Component {

  static propTypes = {
    // form related - passed in
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,

  }

  getLabeledInput(props) {
    return new LabeledInput(props);
  }

  /*
  Render
  ------------------------------------------------------------
  */
  render() {
    const {
      // form related
      initialValues,
      handleSubmit,
      submit,
      submitting,

      // modal related
      show,
      onHide,
    } = this.props;


    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onHide}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>Get Paid!</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
          <form
            onSubmit={handleSubmit}
            className="form-horizontal"
            id="ShowStripe-form"
          >
<p>       VisionHQ is securely integrated with Stripe to process your membership payments and payouts, but we need to connect your bank account to make it all work. You'll need the following information to complete the process:</p>
<ul>
<li styleName="smaller"> Legal business name and EIN (Employer Identification Number)</li>
<li styleName="smaller"> The name on the bank account (typically your legal business name), routing number, and account number</li>
<li styleName="smaller"> Practice Owner's: name, email, mobile phone, DOB, full SSN, and home address</li>
</ul>
<br />
<p>Yes, <strong>you need ALL of that </strong>to validate and connect your bank account.</p>
          </form>
        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>
          <div className="modal-controls">
            <input
              type="button"
              className="modal-control"
              disabled={submitting}
              onClick={submit}
              value="I'm ready to connect my bank account"
            />
          </div>
        </Modal.Footer>

      </Modal>
    );

  }
}
