/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import SideNavDashboard from 'components/SideNavDashboard';
import FaCog from 'react-icons/lib/fa/cog';
import FaQuestion from 'react-icons/lib/fa/question';
import FaMapMarker from 'react-icons/lib/fa/map-marker';
import FaClose from 'react-icons/lib/fa/close';
import FaSearch from 'react-icons/lib/fa/search';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import { setItem, removeItem } from 'utils/localStorage';
import LoadingSpinner from 'components/LoadingSpinner';
import { toggleSearching } from 'containers/App/actions';
import { toast } from 'react-toastify';


// app
import { TENANT } from 'new/tenants'
const { pngLogoOnLight } = TENANT.logos;
import { selectCurrentUser } from 'containers/App/selectors';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
import {
  // fetch
  searchMembers,
  searchMembersFinished,
  filterMembers,
  fetchPatients,
  clearSearchPatients
} from '../../containers/dashboardUpdate/DentistMembersPage/actions';

let timeoutVar;
import styles from './styles.module.css';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps (state) {
  return {
    loggedInUser: selectCurrentUser(state),
    user: selectCurrentUser(state),
    isSearching: state.global.isSearching
  };
}


function mapDispatchToProps (dispatch) {
  return {
    changeRoute: (url) => dispatch(push(url)),
    searchMembers: (name) => dispatch(searchMembers(name)),
    searchMembersFinished: (name)=> dispatch(searchMembersFinished(name)),
    filterMembers: (status) => dispatch(filterMembers(status)),
    toggleSearching:(toggle) => dispatch(toggleSearching(toggle)),
    fetchPatients: (dentistInfoId,initial,searchQuery,searchFilter,startIndex) => dispatch(fetchPatients(dentistInfoId,initial,searchQuery,searchFilter,startIndex)),
    clearSearchPatients:() =>dispatch(clearSearchPatients())
  }
}

/*
NavBar
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class NavBarDashboard extends React.Component {

  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    pathname: PropTypes.string,
    user: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    logo: PropTypes.oneOfType([
      PropTypes.string, // show custom logo
      PropTypes.bool,   // false => show no logo (i.e. custom logo url is loading)
    ]),                       // null => show VisionHQ logo4
    onMemberSearch: PropTypes.func,
    // search / filter patients - state
    currentSearchTerm: PropTypes.string,
    currentFilterTerm: PropTypes.string,

    // search / filter patients - dispatch
    searchMembers: PropTypes.func,
    filterMembers: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      memberSearchTerm: this.props.currentSearchTerm !== null
        ? this.props.currentSearchTerm
        : '',
      searchingSpinner:false
    }
  }

  onOfficeChange =  (value) => {
    if(cookies.get('finishedAndPaid')=='false'){
      let finished=confirm('You have not completed payment. Are you sure you want to leave before clicking "Finish & Pay"?')
      if(finished==true){
        cookies.remove('finishedAndPaid',{ path: '/' });
        let location='/dentist-new/members';
        cookies.set('officeId', value,{ path: '/' });
        window.location=location;
      }
    }else{
      let location='/dentist-new/members';
      cookies.set('officeId', value,{ path: '/' });
      window.location=location;
    }
  }


  returnLinks = {
    '/accounts/dentist-signup': (<Link to="/" styleName="navbar__text">&lt; Home</Link>),
    '/accounts/login': (<Link to="/accounts/login" styleName="navbar__text">&lt; Login</Link>),
  }

  onMemberSearch = (value) => {
    if(this.props.pathname!='/dentist-new/members') this.props.changeRoute('/dentist-new/members');
    if(value==undefined) value=this.state.memberSearchTerm;
    if(value!=undefined){
      clearTimeout(timeoutVar)
      this.props.toggleSearching(true);
      timeoutVar=setTimeout(()=>{
          if(value=='') this.props.clearSearchPatients()
          this.props.filterMembers("all")
          this.props.fetchPatients(cookies.get("officeId"),(value==''?true:false),value,"all",0);
          Promise.resolve(this.props.searchMembers(value)).then(()=>{
            this.props.searchMembersFinished(value);
            this.props.toggleSearching(false);
        })
      }
      ,500);
    }
  }

  updateMemberSearchTerm = (evt) => {
    this.setState({
      ...this.state,
      memberSearchTerm: evt.target.value,
    })
    this.onMemberSearch(evt.target.value);
  }

  updateSearchTerm = (evt) => {
    if(evt.key == "Enter"){
      this.onMemberSearch(undefined);
    }
  }

  onBackToAdmin =   () => {
    localStorage.setItem('auth_token', cookies.get('beccascookie'));
    cookies.remove('officeId',{ path: '/' });
    cookies.remove('beccascookie',{ path: '/' });
    setTimeout(() => window.location='/admin/dentists', 250);
}

  copyEmail=(e) =>{
    
    var inputtext=document.querySelector("#emailSelector");
    inputtext.select();
    document.execCommand('copy');
    toast.success("Copied to clipboard: " + document.getElementById("emailSelector").value)
    //toast.success("Copied to clipboard: " + document.getElementById("emailSelector").value);
  }


  selectTab = (key) => {
    if(cookies.get('finishedAndPaid')=='false'){
      let finished=confirm('You have not completed payment. Are you sure you want to leave before clicking "Finish & Pay"?')
      key=finished==false?null:key;
      if(finished==true) cookies.remove('finishedAndPaid',{ path: '/' });
    }
    switch (key) {
      case 'edit-profile':
        this.props.changeRoute('/dentist-new/edit-profile');
        break;
      case 'custom':
        this.props.changeRoute('/dentist-new/custom-membership');
        break;

      case 'users':
        this.props.changeRoute('/dentist-new/users');
        break;
      case 'reviews':
        this.props.changeRoute('/dentist-new/reviews');
        break;
      case 'members':
        this.props.changeRoute('/dentist-new/members');
        break;
      case 'profile':
        this.props.changeRoute('/dentist-new/profile');
        break;
      case 'contact-admin':
        this.props.changeRoute('/dentist-new/contact-admin');
        break;
      default:
        // Don't do anything... we should never actually reach here.
        break;
    }
  }

  render () {
    const {
      user,
      isSearching
     } = this.props;

    const { firstName,
       lastName,
      } = this.props.loggedInUser;
    const {memberSearchTerm}=this.state;

    const fullName = `${firstName} ${lastName}`;
    const returnLink = this.returnLinks.hasOwnProperty(this.props.pathname)
                     ? this.returnLinks[this.props.pathname]
                     : null;
    // else - show no logo

      return (

        <Navbar fixedTop styleName={this.props.withbanner==true?"navbar withbanner":"navbar"}>
            <Nav className="col-sm-2" styleName="navbar__col">
            {(user.dentistInfo!=undefined&&user.dentistInfo.memberships.length==0)?(
              <div href="/dentist-new/get-started"><img src={user.dentistInfo!=undefined&&user.dentistInfo.dentalAssociations!=undefined?user.dentistInfo.dentalAssociations.logo:pngLogoOnLight} alt={TENANT.name} styleName="navbar__brand__img" /></div>
            ):(
              <img src={user.dentistInfo!=undefined&&user.dentistInfo.dentalAssociations!=undefined?user.dentistInfo.dentalAssociations.logo:pngLogoOnLight} alt={TENANT.name} styleName="navbar__brand__img" />)}
              {returnLink}
            </Nav>
            <Nav className="col-sm-3" styleName="search-nav">
                <InputGroup>
                  <FormControl
                    id="searchBox"
                    type="text"
                    placeholder="Search for member"
                    className="form-control--large"
                    defaultValue={memberSearchTerm}
                    // Rebecca autofill off
                    onChange={window.location.href.indexOf('localhost') ==-1?this.updateMemberSearchTerm:null}
                    onKeyDown={(e) => { window.location.href.indexOf('localhost') ==-1?this.updateSearchTerm(e):null}}
                   />
                   {isSearching?(<InputGroup.Button><LoadingSpinner showOnlyIcon={true} size={25}/></InputGroup.Button>):(
                  <InputGroup.Button onClick={this.onMemberSearch}>
                    <span className="btn btn-default">
                      <FaSearch />
                    </span>
                  </InputGroup.Button>
                   )}
                </InputGroup>
              </Nav>

            <Nav className="col-sm-7" pullRight>
              <div styleName="location-selector">
                <div styleName="location-dropdown">
                  <div styleName="location-welcome">{user.firstName!=undefined?(<span>{user.firstName} <span styleName="url-separator">|</span><input onChange={this.copyEmail} onClick={this.copyEmail} type="text" value={user.userEmail} id="emailSelector" styleName="url" style={{width:((user.userEmail.length + 1) * 6) + 'px'}}/></span>):"Welcome"}</div>
                    <NavDropdown styleName="navbar__brand__link" title={(user.dentistInfo!=undefined&&cookies.get('officeId')!=""?user.dentistInfo.officeName:'Master Dashboard')} styleName="topdropdown" id="topdropdown">

                    {user.otherOffices!=undefined&&user.otherOffices.length>1&&(
                      <MenuItem styleName="header-link locations">
                        <div styleName="likeGmail">
                          <div styleName="pulldown-location master-dashboard">
                                <div onClick={() => {this.onOfficeChange('')}}>Master Dashboard</div>
                          </div>
                          {user.otherOffices.map(office=>(
                          <div key={office.id} styleName="pulldown-location">
                            <div onClick={() => {this.onOfficeChange(office.id)}}>{office.officeName}</div>
                          </div>
                          ))}
                        </div>
                      </MenuItem>
                      )}

                      <MenuItem styleName={"header-link top-link" + (this.props.pathname.indexOf('dentist-new/profile') > -1 ? " header-link--current" : "")} onClick={() =>this.selectTab('profile')}>
                        <FaCog/>Settings
                      </MenuItem>

                      <MenuItem styleName={"header-link top-link" + (this.props.pathname.indexOf('contact-admin') > -1 ? " header-link--current" : "")} onClick={() =>this.selectTab('contact-admin')}>
                        <FaQuestion/>Support
                      </MenuItem>

                      <MenuItem styleName="header-link top-link" onClick={cookies.set('userId',this.props.user.id,{path:'/'})} href="/accounts/login">
                        <FaClose/>Log Out
                      </MenuItem>


                  </NavDropdown>
                  <div id="pixel"></div>
                  {cookies.get('beccascookie')&&(
                    <NavDropdown styleName="navbar__brand__link topdropdownsecond" title='Logged In as Admin' id="adminnavdropdown">
                      <MenuItem styleName="header-link top-link" onClick={() =>this.onBackToAdmin()}>
                        <FaClose/>Back to Admin
                      </MenuItem>


                  </NavDropdown>
                  )}
                </div>
                <div styleName="location-logo">
                  <img src={user.dentistInfo!=undefined&&cookies.get('officeId')!=""&&user.dentistInfo.logo!=null?user.dentistInfo.logo:''} styleName="mini_logo" onClick={() =>this.selectTab('contact-admin')}/>
                </div>
              </div>
            </Nav>

        </Navbar>
      );
  }
}
